import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@rebass/emotion';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Content, { HTMLContent } from '../components/Content';
import Heading from '../components/Heading';
import Text from '../components/Text';
import Author from '../components/Author';

import ShareLinks from '../modules/ShareLinks';

export const BlogPost = ({
  content,
  contentComponent,
  coverImage,
  coverImagePost,
  description,
  title,
  shortTitle,
  author,
  date,
  slug,
}) => {
  const BlogContent = contentComponent || Content;
  return (
    <Layout dark>
      <SEO image={`https://citra.co${coverImage.childImageSharp.fluid.src}`} title={`Citra. ${title}`} keywords={[`blog`, `ux`, `product`, `design`, `studio`]} />
      <Box bg="lightBeige">
        <Flex py={['12%', '10%', '6%']} flexWrap="wrap" justifyContent="center">
          <Box width={[11/12, 9/12, 8/12, 6/12]} px={'2%'}>
            <Heading data-aos="fade-up" as="h2" color="darkGray" pb={3} fontSize={[5, 6, 7]}>{title}</Heading>
            <Text data-aos="fade-up" fontSize={[2, 3]}>{description}</Text>
            <Box data-aos="fade-up" mt={[4, 6]}>
              <Author data-aos="fade-up" date={date} person={author} />
            </Box>
          </Box>
          {coverImagePost &&
            <Box width={[1, 8/10]} height={'200px'} mb={'-20%'} mt={6} data-aos="fade-up">
              <Image style={{ width: '100%', maxHeight: '600px' }} alt={title} fluid={coverImage.childImageSharp.fluid} />
            </Box>
          }
        </Flex>
      </Box>
      <Flex bg="white" flexWrap="wrap" p={coverImagePost ? ['20% 0 12% 0', '18% 0 4% 0'] : ['12% 0 12% 0', '4% 0 4% 0']} justifyContent="center">
        <Box width={[11/12, 9/12, 8/12, 6/12]} px={'2%'} pt={0}>
          <BlogContent content={content} />
          <Flex flexWrap="wrap">
            <Box width={1/2}>
              <Author data-aos="fade-up" mt={6} date={date} person={author} />
            </Box>
            <Box width={1/2}>
              <ShareLinks data-aos="fade-up"  mt={6} title={title} slug={slug} />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Layout>
  )
};

const BlogTemplate = ({ data }) => {
  const post = data.markdownRemark;
  return (
    <BlogPost
      content={post.htmlAst}
      contentComponent={HTMLContent}
      coverImage={post.frontmatter.coverImage}
      coverImagePost={post.frontmatter.coverImagePost}
      description={post.frontmatter.description}
      date={post.frontmatter.date}
      title={post.frontmatter.title}
      shortTitle={post.frontmatter.shortTitle}
      author={post.frontmatter.author}
      slug={post.fields.slug}
    />
  );
};

export default BlogTemplate;

BlogTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        coverImage: PropTypes.shape,
        coverImagePost: PropTypes.bool,
        date: PropTypes.string,
        title: PropTypes.string,
        shortTitle: PropTypes.string,
        description: PropTypes.string,
        author: PropTypes.string,
      }),
    }),
  }),
};

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        coverImage {
          childImageSharp {
            fluid(maxWidth: 2400) {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
        coverImagePost
        title
        author
        shortTitle
        description
      }
    }
  }
`;
