import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Link } from '@rebass/emotion';
import styled from '@emotion/styled';

import Text from '../components/Text';

import FacebookIcon from '../images/facebook.inline.svg';
import LinkedinIcon from '../images/linkedin.inline.svg';
import TwitterIcon from '../images/twitter.inline.svg';

const ShareLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const Facebook = styled(FacebookIcon)`
  fill: ${props => props.theme.colors.darkGray};
`;

const Twitter = styled(TwitterIcon)`
  fill: ${props => props.theme.colors.darkGray};
`;

const Linkedin = styled(LinkedinIcon)`
  fill: ${props => props.theme.colors.darkGray};
`;

const ShareLinks = ({
  title,
  slug,
   ...props }) => {
  return (
      <Flex justifyContent="flex-end" flexWrap="wrap" {...props}>
        <Box width={1} alignSelf="flex-end">
          <Text>Share this post</Text>
        </Box>
        <Box>
          <ShareLink
            href={`https://twitter.com/intent/tweet/?text=${
              title
            }&url=https://citra.co${slug}&via=startwithcitra`}
            target="_blank"
          >
              <Box mr={3} width={["24px", "28px"]}>
                <Twitter />
              </Box>
          </ShareLink>
        </Box>
        <Box>
          <ShareLink
            href={`https://www.facebook.com/sharer/sharer.php?u=https://citra.co${
               slug
             }`}
            target="_blank"
          >
            <Box mr={3} width={["24px", "26px"]}>
              <Facebook />
            </Box>
          </ShareLink>
        </Box>
        <Box>
          <ShareLink
            href={`https://www.linkedin.com/shareArticle?url=https://citra.co${
              slug
            }&title=${title}&source=${title}`}
            target="_blank"
          >
            <Box width={["24px", "26px"]}>
              <Linkedin />
            </Box>
          </ShareLink>
        </Box>
      </Flex>
  );
};


ShareLinks.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default ShareLinks;
